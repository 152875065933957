<template>
  <div class="template-accordion-wrapper">
    <div class="title-subtitle-wrapper">
      <p class="cursor-pointer" @click="show = !show">
        {{ title }}
        <i
          class="arrow-icon el-icon-arrow-down"
          :class="{'rotate': show}"
        />
      </p>
      <span v-if="subtitle" class="text-subtitle m-0">
        {{ subtitle }}
      </span>
    </div>
    <Transition name="slide">
      <div v-show="show">
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'IbSingleAccordion',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.template-accordion-wrapper {

  .title-subtitle-wrapper {
    margin: 0 0 28px;

  p {
    width: fit-content;
    font-size: 24px;
    margin-bottom: 0;
  }

  .arrow-icon {
    transition: transform .3s;

    &.rotate {
      transform: rotate(-180deg);
    }
  }

  span {
    opacity: 0.5;
    font-size: 18px;
    line-height: 1.44;
    color: #292f4d;
    }
  }
}
</style>
