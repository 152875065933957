import Vue from 'vue'
import { actions } from './actions'
import { getters } from './getters'

import ideaPlan from './ideaPlan'
import ideaWhiteBoard from './whiteboard'
import ownership from './ownership'

const getDefaultState = () => {
  return {
    id: 0,
    ownerId: null,
    role: null,
    createdAt: null,
    owner: {
      email: '',
      firstName: '',
      lastName: ''
    },
    ownerSubscription: {
      subscribed: false,
      subscription: null
    },
    ownerAiRequests: {
      requests: 0,
      spent: 0,
      additionalRequests: 0,
      additionalRequestsSpent: 0,
      amount: 0,
      amountSpent: 0
    },
    name: '',
    slogan: '',
    ideaSettings: {},
    catalogueIdeaTemplate: null,
    ideaGuide: {
      ideaGuide: false,
      productGuide: false,
      promotionGuide: false,
      distributionGuide: false,
      partnerGuide: false,
      marketGuide: false,
      marketPotentialGuide: false,
      customerGuide: false,
      competitorGuide: false,
      startupCostGuide: false,
      startupCostFinancialPlanGuide: false,
      financingGuide: false,
      financingFinancialPlanGuide: false,
      managementTeamGuide: false,
      staffPayrollGuide: false,
      ownershipGuide: false,
      staffPayrollFinancialPlanGuide: false,
      revenueStreamGuide: false,
      revenueStreamFinancialPlanGuide: false,
      directCostGuide: false,
      directCostFinancialPlanGuide: false,
      marketingBudgetGuide: false,
      marketingBudgetFinancialPlanGuide: false,
      otherOverheadsGuide: false,
      otherOverheadsFinancialPlanGuide: false,
      profitCashFlowGuide: false,
      profitCashFlowFinancialPlanGuide: false,
      swotAnalysisGuide: false,
      ideaScoreGuide: false
    },
    tipOpen: {
      idea: false,
      market: false,
      partner: false,
      product: false,
      customers: false,
      financing: false,
      ownership: false,
      promotion: false,
      competitors: false,
      'direct-costs': false,
      distribution: false,
      'startup-cost': false,
      'swot-analysis': false,
      managementTeam: false,
      'revenue-stream': false,
      'other-overheads': false,
      staffAndPayroll: false,
      'market-potential': false,
      'marketing-budget': false,
      'profit-and-cash-flow': false
    },
    ideaGuideExamples: {},
    tips: {},
    catalogueCategory: {},
    catalogueIdea: {},
    storyMode: {
      concept: {
        idea: {
          why: '',
          what: '',
          whyFromTemplate: false,
          whatFromTemplate: false
        },
        product: {
          uniqueSellingPropositions: [],
          products: []
        },
        promotion: {
          promotionalActivities: []
        },
        distribution: {
          distributions: []
        },
        partner: {
          partners: []
        }
      },
      research: {
        market: {
          coverageElement: {
            coverageElementId: 0,
            title: '',
            text: '',
            image: ''
          },
          coverageElementSelected: false,
          growthRateElement: {
            growthRateElementId: 0,
            title: '',
            text: '',
            image: ''
          },
          growthRateElementSelected: false
        },
        customers: {
          customers: []
        },
        competitors: {
          competitors: []
        },
        swotAnalysis: {
          strengths: '',
          strengthsFromTemplate: false,
          weaknesses: '',
          weaknessesFromTemplate: false,
          opportunities: '',
          opportunitiesFromTemplate: false,
          threats: '',
          threatsFromTemplate: false
        },
        marketPotential: {
          id: null,
          total_population: null,
          potential_customers: null,
          annual_spending: null,
          target_customers: null,
          market_share: null
        }
      },
      setUp: {
        startupCosts: {
          startupCosts: [],
          breakdown: {},
          timeline: {},
          table: {}
        },
        financing: {
          finances: [],
          breakdown: {},
          timeline: {},
          table: {}
        },
        managementTeam: {
          members: []
        },
        staffAndPayrolls: {
          staffAndPayrolls: [],
          breakdown: {},
          timeline: {},
          table: {},
          laborBurden: {}
        }
      },
      forecast: {
        revenueStreams: {
          revenueStreams: [],
          breakdown: {},
          timeline: {},
          table: {},
          salesTaxRate: []
        },
        directCosts: {
          directCosts: [],
          breakdown: {},
          timeline: {},
          table: {}
        },
        marketingBudget: {
          marketingElements: [],
          breakdown: {},
          timeline: {},
          table: {}
        },
        otherOverheads: {
          otherOverheads: [],
          breakdown: {},
          timeline: {},
          table: {}
        },
        profitAndCashFlow: {
          dividends: [],
          cashCycle: {},
          incomeTax: {},
          calculation: {}
        },
        totalCosts: {
          calculation: {}
        }
      },
      overview: {
        overviewElements: []
      },
      validation: {
        ideaScore: []
      },
      common: {
        selectedExamples: {
          ideaExamples: 3,
          productExamples: 8,
          promotionExamples: 13,
          distributionExamples: 88,
          partnerExamples: 98,
          marketExamples: 18,
          customersExamples: 23,
          competitorsExamples: 28,
          marketPotentialExamples: 93,
          startupCostsExamples: 33,
          financingExamples: 38,
          ownershipExamples: 1,
          managementTeamExamples: 43,
          employeesAndPayrollExamples: 48,
          checklistExamples: 1,
          revenueStreamsExamples: 53,
          directCostsExamples: 58,
          marketingBudgetExamples: 63,
          otherOverheadsExamples: 68,
          profitAndCashFlowExamples: 73,
          industryStandardsExamples: 1,
          ideaScoreExamples: 1,
          ideaPlanExamples: 78,
          swotAnalysisExamples: 83
        },
        completedSections: {
          concept: false,
          research: false,
          setUp: false,
          projections: false
        },
        completedSteps: {
          ideaCompleted: false,
          productCompleted: false,
          promotionCompleted: false,
          marketCompleted: false,
          marketPotentialCompleted: false,
          customersCompleted: false,
          competitorsCompleted: false,
          swotAnalysisCompleted: false,
          startupCostsCompleted: false,
          financingCompleted: false,
          ownershipCompleted: false,
          managementTeamCompleted: false,
          employeesAndPayrollCompleted: false,
          // checklistCompleted: false,
          revenueStreamsCompleted: false,
          directCostsCompleted: false,
          marketingBudgetCompleted: false,
          otherOverheadsCompleted: false,
          profitAndCashFlowCompleted: false,
          industryStandardsCompleted: false,
          ideaScoreCompleted: false,
          distributionCompleted: false,
          partnerCompleted: false
          // industryStandardsCompleted: false,
          // ideaScoreCompleted: false
        },
        moreDetails: {
          checklistMoreDetails: '',
          competitorsMoreDetails: '',
          customersMoreDetails: '',
          directCostsMoreDetails: '',
          employeesAndPayrollMoreDetails: '',
          financingMoreDetails: '',
          ideaMoreDetails: '',
          ideaScoreMoreDetails: '',
          industryStandardsMoreDetails: '',
          startupCostMoreDetails: '',
          managementTeamMoreDetails: '',
          marketMoreDetails: '',
          marketPotentialMoreDetails: '',
          marketingBudgetMoreDetails: '',
          otherOverheadsMoreDetails: '',
          ownershipMoreDetails: '',
          productMoreDetails: '',
          profitAndCashFlowMoreDetails: '',
          promotionMoreDetails: '',
          revenueStreamsMoreDetails: '',
          ideaPlanMoreDetails: '',
          swotAnalysisMoreDetails: '',
          distributionMoreDetails: '',
          partnerMoreDetails: ''
        },
        moreDetailsVisibility: {
          checklistMoreDetailsVisible: false,
          competitorsMoreDetailsVisible: false,
          customersMoreDetailsVisible: false,
          directCostsMoreDetailsVisible: false,
          employeesAndPayrollMoreDetailsVisible: false,
          financingMoreDetailsVisible: false,
          ideaMoreDetailsVisible: false,
          ideaScoreMoreDetailsVisible: false,
          industryStandardsMoreDetailsVisible: false,
          startupCostMoreDetailsVisible: false,
          managementTeamMoreDetailsVisible: false,
          marketMoreDetailsVisible: false,
          marketingBudgetMoreDetailsVisible: false,
          otherOverheadsMoreDetailsVisible: false,
          ownershipMoreDetailsVisible: false,
          productMoreDetailsVisible: false,
          profitAndCashFlowMoreDetailsVisible: false,
          promotionMoreDetailsVisible: false,
          distributionMoreDetailsVisible: false,
          partnerMoreDetailsVisible: false,
          revenueStreamsMoreDetailsVisible: false,
          ideaPlanMoreDetailsVisible: false,
          swotAnalysisMoreDetailsVisible: false,
          marketPotentialMoreDetailsVisible: false
        }
      }
    },
    journal: {
      journalSwitchBars: []
    },
    ideaPlanNote: {
      ideaPlanNotes: []
    },
    completedStepsCount: 0
  }
}

const state = getDefaultState()

// noinspection JSUnusedGlobalSymbols
const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setIdea (state, payload) {
    const { idea } = payload

    state.id = idea.id
    state.ownerId = idea.ownerId
    state.owner = idea.owner
    state.ownerSubscription = idea.ownerSubscription
    state.name = idea.name
    state.slogan = idea.slogan
    state.role = idea.role
    state.createdAt = idea.createdAt
    state.template = idea.template
    state.fromTemplate = idea.fromTemplate
    state.ideaSettings = idea.ideaSettings
    state.ideaGuide = idea.ideaGuide
    state.ideaGuideExamples = idea.ideaGuideExamples
    state.tips = idea.tips
    state.catalogueCategory = idea.catalogueCategory
    state.catalogueIdea = idea.catalogueIdea
    state.journal.journalSwitchBars = idea.journalSwitchBars
    state.ideaPlan.ideaPlans = idea.ideaPlans
    state.ownership.ownership = idea.ownership ?? ownership.state.ownership
    state.ideaPlanNote.ideaPlanNotes = idea.ideaPlanNotes
    state.completedStepsCount = idea.completedSteps.length
    state.ideaWhiteBoard = idea.ideaWhiteboard
    state.catalogueIdeaTemplate = idea.catalogueIdeaTemplate ? idea.catalogueIdeaTemplate : null
    state.tipOpen = idea.tipOpen.data

    if (idea.ownerAiRequests) {
      state.ownerAiRequests = idea.ownerAiRequests
    }

    for (const completedStepItem in idea.completedSteps) {
      if (Object.prototype.hasOwnProperty.call(idea.completedSteps, completedStepItem)) {
        state.storyMode.common.completedSteps[completedStepItem] = idea.completedSteps[completedStepItem]
      }
    }

    for (const moreDetail in idea.moreDetails) {
      if (Object.prototype.hasOwnProperty.call(idea.moreDetails, moreDetail)) {
        state.storyMode.common.moreDetails[moreDetail] = idea.moreDetails[moreDetail]
      }
    }

    for (const moreDetailVisibility in idea.moreDetailsVisibility) {
      if (Object.prototype.hasOwnProperty.call(idea.moreDetailsVisibility, moreDetailVisibility)) {
        state.storyMode.common.moreDetailsVisibility[moreDetailVisibility] = idea.moreDetailsVisibility[moreDetailVisibility]
      }
    }

    for (const selectedExample in idea.selectedExamples) {
      if (Object.prototype.hasOwnProperty.call(idea.selectedExamples, selectedExample)) {
        state.storyMode.common.selectedExamples[selectedExample] = idea.selectedExamples[selectedExample]
      }
    }

    state.storyMode.common.completedSections.concept = !state.storyMode.common.completedSections.concept ? getters.isConceptCompleted(state) : true
    state.storyMode.common.completedSections.research = !state.storyMode.common.completedSections.research ? getters.isResearchCompleted(state) : true
    state.storyMode.common.completedSections.setUp = !state.storyMode.common.completedSections.setUp ? getters.isSetUpCompleted(state) : true
    state.storyMode.common.completedSections.projections = !state.storyMode.common.completedSections.projections ? getters.isProjectionsCompleted(state) : true
    // StoryMode - Concept
    state.storyMode.concept.product.uniqueSellingPropositions = idea.uniqueSellingPropositions
    state.storyMode.concept.idea.why = idea.ideaWhy
    state.storyMode.concept.idea.whyFromTemplate = idea.ideaWhyFromTemplate
    state.storyMode.concept.idea.what = idea.ideaWhat
    state.storyMode.concept.idea.whatFromTemplate = idea.ideaWhatFromTemplate
    state.storyMode.concept.product.products = idea.products
    state.storyMode.concept.promotion.promotionalActivities = idea.promotionalActivities
    state.storyMode.concept.distribution.distributions = idea.distributions
    state.storyMode.concept.partner.partners = idea.partners
    // StoryMode - Research
    state.storyMode.research.competitors.competitors = idea.competitors
    state.storyMode.research.customers.customers = idea.customers
    state.storyMode.research.swotAnalysis.strengths = idea.swotAnalysisStrengths
    state.storyMode.research.swotAnalysis.strengthsFromTemplate = idea.swotAnalysisStrengthsFromTemplate
    state.storyMode.research.swotAnalysis.weaknesses = idea.swotAnalysisWeaknesses
    state.storyMode.research.swotAnalysis.weaknessesFromTemplate = idea.swotAnalysisWeaknessesFromTemplate
    state.storyMode.research.swotAnalysis.opportunities = idea.swotAnalysisOpportunities
    state.storyMode.research.swotAnalysis.opportunitiesFromTemplate = idea.swotAnalysisOpportunitiesFromTemplate
    state.storyMode.research.swotAnalysis.threats = idea.swotAnalysisThreats
    state.storyMode.research.swotAnalysis.threatsFromTemplate = idea.swotAnalysisThreatsFromTemplate
    state.storyMode.research.marketPotential = idea.marketPotential
    state.storyMode.research.market.coverageElement = idea.coverageElement
    state.storyMode.research.market.coverageElementSelected = !!idea.coverageElement
    state.storyMode.research.market.growthRateElement = idea.growthRateElement
    state.storyMode.research.market.growthRateElementSelected = !!idea.growthRateElement
    // StoryMode - SetUp
    state.storyMode.setUp.startupCosts.startupCosts = idea.startupCosts
    state.storyMode.setUp.financing.finances = idea.financing
    state.storyMode.setUp.managementTeam.members = idea.members
    state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls = idea.staffAndPayroll
    state.storyMode.setUp.staffAndPayrolls.laborBurden = idea.laborBurden
    // StoryMode - Forecast
    state.storyMode.forecast.directCosts.directCosts = idea.directCosts
    state.storyMode.forecast.revenueStreams.revenueStreams = idea.revenueStreams
    state.storyMode.forecast.revenueStreams.salesTaxRate = idea.salesTaxRate
    state.storyMode.forecast.otherOverheads.otherOverheads = idea.otherOverheads
    state.storyMode.forecast.profitAndCashFlow.dividends = idea.dividends
    state.storyMode.forecast.profitAndCashFlow.cashCycle = idea.cashCycle
    state.storyMode.forecast.profitAndCashFlow.incomeTax = idea.incomeTax
    state.storyMode.forecast.marketingBudget.marketingElements = idea.marketingElements
    // StoryMode - Other
    state.storyMode.validation.ideaScore = idea.ideaScore
    state.storyMode.overview.overviewElements = idea.overviewElements

    // StoryMode - Financial Plan Calculations
    if (idea.storyMode) {
      state.storyMode.forecast.totalCosts.calculation = idea.storyMode.totalCosts
      state.storyMode.forecast.profitAndCashFlow.calculation = idea.storyMode.profitAndCashFlow
      state.storyMode.setUp.startupCosts.breakdown = idea.storyMode.startupCosts.breakdown
      state.storyMode.setUp.startupCosts.timeline = idea.storyMode.startupCosts.timeline
      state.storyMode.setUp.startupCosts.table = idea.storyMode.startupCosts.table
      state.storyMode.setUp.staffAndPayrolls.breakdown = idea.storyMode.staffAndPayroll.breakdown
      state.storyMode.setUp.staffAndPayrolls.timeline = idea.storyMode.staffAndPayroll.timeline
      state.storyMode.setUp.staffAndPayrolls.table = idea.storyMode.staffAndPayroll.table
      state.storyMode.setUp.financing.breakdown = idea.storyMode.financing.breakdown
      state.storyMode.setUp.financing.timeline = idea.storyMode.financing.timeline
      state.storyMode.setUp.financing.table = idea.storyMode.financing.table
      state.storyMode.forecast.revenueStreams.breakdown = idea.storyMode.revenueStreams.breakdown
      state.storyMode.forecast.revenueStreams.timeline = idea.storyMode.revenueStreams.timeline
      state.storyMode.forecast.revenueStreams.table = idea.storyMode.revenueStreams.table
      state.storyMode.forecast.directCosts.breakdown = idea.storyMode.directCosts.breakdown
      state.storyMode.forecast.directCosts.timeline = idea.storyMode.directCosts.timeline
      state.storyMode.forecast.directCosts.table = idea.storyMode.directCosts.table
      state.storyMode.forecast.marketingBudget.breakdown = idea.storyMode.marketingBudget.breakdown
      state.storyMode.forecast.marketingBudget.timeline = idea.storyMode.marketingBudget.timeline
      state.storyMode.forecast.marketingBudget.table = idea.storyMode.marketingBudget.table
      state.storyMode.forecast.otherOverheads.breakdown = idea.storyMode.otherOverheads.breakdown
      state.storyMode.forecast.otherOverheads.timeline = idea.storyMode.otherOverheads.timeline
      state.storyMode.forecast.otherOverheads.table = idea.storyMode.otherOverheads.table
    }
  },

  setIdeaOwnerEmail (state, email) {
    state.owner.email = email
  },

  setIdeaOwnerImage (state, image) {
    state.owner.image = image
  },

  setCompletedSteps (state, payload) {
    for (const completedStepItem in payload.completedSteps) {
      if (Object.prototype.hasOwnProperty.call(payload.completedSteps, completedStepItem)) {
        state.storyMode.common.completedSteps[completedStepItem] = payload.completedSteps[completedStepItem]
      }
    }
  },

  setManagementTeam (state, payload) {
    state.storyMode.setUp.managementTeam.members = payload
  },

  setCompetitors (state, payload) {
    state.storyMode.research.competitors.competitors = payload
  },

  setCustomers (state, payload) {
    state.storyMode.research.customers.customers = payload
  },

  setMarket (state, payload) {
    state.storyMode.research.market.coverageElement = payload.coverageElement
    state.storyMode.research.market.coverageElementSelected = !!payload.coverageElement
    state.storyMode.research.market.growthRateElement = payload.growthRateElement
    state.storyMode.research.market.growthRateElementSelected = !!payload.growthRateElement
  },

  increaseCompletedStepsCount (state) {
    state.completedStepsCount++
  },

  decreaseCompletedStepsCount (state) {
    state.completedStepsCount--
  },

  setIdeaWhy (state, why) {
    state.storyMode.concept.idea.why = why
  },

  setIdeaWhyFromTemplate (state, fromTemplate) {
    state.storyMode.concept.idea.whyFromTemplate = fromTemplate
  },

  setIdeaWhat (state, what) {
    state.storyMode.concept.idea.what = what
  },

  setIdeaWhatFromTemplate (state, fromTemplate) {
    state.storyMode.concept.idea.whatFromTemplate = fromTemplate
  },

  setProduct (state, payload) {
    state.storyMode.concept.product.products = payload
  },

  addProduct (state, product) {
    state.storyMode.concept.product.products.unshift(product)
  },

  updateProduct (state, product) {
    const index = state.storyMode.concept.product.products.indexOf(
      state.storyMode.concept.product.products.find(productToUpdate => productToUpdate.id === product.id)
    )
    Vue.set(state.storyMode.concept.product.products, index, product)
  },

  removeProduct (state, productId) {
    const index = state.storyMode.concept.product.products.indexOf(
      state.storyMode.concept.product.products.find(productToUpdate => productToUpdate.id === productId)
    )

    state.storyMode.concept.product.products.splice(index, 1)
  },

  setDistribution (state, payload) {
    state.storyMode.concept.distribution.distributions = payload
  },

  addDistribution (state, distribution) {
    state.storyMode.concept.distribution.distributions.unshift(distribution)
  },

  updateDistribution (state, distribution) {
    const index = state.storyMode.concept.distribution.distributions.indexOf(
      state.storyMode.concept.distribution.distributions.find(distributionToUpdate => distributionToUpdate.id === distribution.id)
    )
    Vue.set(state.storyMode.concept.distribution.distributions, index, distribution)
  },

  removeDistribution (state, distributionId) {
    const index = state.storyMode.concept.distribution.distributions.indexOf(
      state.storyMode.concept.distribution.distributions.find(distributionToUpdate => distributionToUpdate.id === distributionId)
    )

    state.storyMode.concept.distribution.distributions.splice(index, 1)
  },

  setPartners (state, payload) {
    state.storyMode.concept.partner.partners = payload
  },

  addPartner (state, partner) {
    state.storyMode.concept.partner.partners.unshift(partner)
  },

  updatePartner (state, partner) {
    const index = state.storyMode.concept.partner.partners.indexOf(
      state.storyMode.concept.partner.partners.find(partnerToUpdate => partnerToUpdate.id === partner.id)
    )
    Vue.set(state.storyMode.concept.partner.partners, index, partner)
  },

  removePartner (state, partnerId) {
    const index = state.storyMode.concept.partner.partners.indexOf(
      state.storyMode.concept.partner.partners.find(partnerToUpdate => partnerToUpdate.id === partnerId)
    )

    state.storyMode.concept.partner.partners.splice(index, 1)
  },

  addCustomer (state, customer) {
    state.storyMode.research.customers.customers.unshift(customer)
  },

  updateCustomer (state, customer) {
    const index = state.storyMode.research.customers.customers.findIndex(customerToUpdate => customerToUpdate.id === customer.id)
    Vue.set(state.storyMode.research.customers.customers, index, customer)
  },

  removeCustomer (state, customerId) {
    const index = state.storyMode.research.customers.customers.findIndex(customerToUpdate => customerToUpdate.id === customerId)

    state.storyMode.research.customers.customers.splice(index, 1)
  },

  setSwotAnalysisStrengths (state, strengths) {
    state.storyMode.research.swotAnalysis.strengths = strengths
  },

  setSwotAnalysisWeaknesses (state, weaknesses) {
    state.storyMode.research.swotAnalysis.weaknesses = weaknesses
  },

  setSwotAnalysisOpportunities (state, opportunities) {
    state.storyMode.research.swotAnalysis.opportunities = opportunities
  },

  setSwotAnalysisThreats (state, threats) {
    state.storyMode.research.swotAnalysis.threats = threats
  },

  setSwotAnalysisFromTemplate (state, { type, value }) {
    state.storyMode.research.swotAnalysis[type] = value
  },

  setMarketPotential (state, marketPotential) {
    state.storyMode.research.marketPotential = Object.assign({}, marketPotential)
  },

  setPromotionalActivity (state, payload) {
    state.storyMode.concept.promotion.promotionalActivities = payload
  },

  addPromotionalActivity (state, promotionalActivity) {
    state.storyMode.concept.promotion.promotionalActivities.unshift(promotionalActivity)
  },

  updatePromotionalActivity (state, promotionalActivity) {
    const index = state.storyMode.concept.promotion.promotionalActivities.indexOf(
      state.storyMode.concept.promotion.promotionalActivities.find(promotionalActivityToUpdate => promotionalActivityToUpdate.id === promotionalActivity.id)
    )
    Vue.set(state.storyMode.concept.promotion.promotionalActivities, index, promotionalActivity)
  },

  removePromotionalActivity (state, promotionalActivityId) {
    const index = state.storyMode.concept.promotion.promotionalActivities.indexOf(
      state.storyMode.concept.promotion.promotionalActivities.find(promotionalActivityToUpdate => promotionalActivityToUpdate.id === promotionalActivityId)
    )
    state.storyMode.concept.promotion.promotionalActivities.splice(index, 1)
  },

  addTeamMember (state, member) {
    state.storyMode.setUp.managementTeam.members.unshift(member)
  },

  updateTeamMember (state, member) {
    const index = state.storyMode.setUp.managementTeam.members.indexOf(
      state.storyMode.setUp.managementTeam.members.find(memberToUpdate => memberToUpdate.id === member.id)
    )
    Vue.set(state.storyMode.setUp.managementTeam.members, index, member)
  },

  removeTeamMember (state, memberId) {
    const index = state.storyMode.setUp.managementTeam.members.indexOf(
      state.storyMode.setUp.managementTeam.members.find(memberToUpdate => memberToUpdate.id === memberId)
    )
    state.storyMode.setUp.managementTeam.members.splice(index, 1)
  },

  setMoreDetails (state, payload) {
    state.storyMode.common.moreDetails[payload.conceptName] = payload.content
  },

  setMoreDetailsMerge (state, payload) {
    state.storyMode.common.moreDetails = {
      ...state.storyMode.common.moreDetails,
      ...payload
    }
  },

  toggleMoreDetailsVisibility (state, payload) {
    state.storyMode.common.moreDetailsVisibility[payload.key] = payload.value

    if (payload.value) {
      const keys = Object.keys(state.storyMode.common.moreDetailsVisibility)

      keys.forEach(function (key) {
        state.storyMode.common.moreDetailsVisibility[key] = true
      })
    }
  },

  changeCompletedStep (state, payload) {
    state.storyMode.common.completedSteps[payload.step] = payload.completed
  },

  changeSelectedExample (state, payload) {
    state.storyMode.common.selectedExamples[payload.type] = payload.active
  },

  setUniqueSellingPropositions (state, payload) {
    state.storyMode.concept.product.uniqueSellingPropositions = payload
  },

  removeUniqueSellingPropositions (state, index) {
    state.storyMode.concept.product.uniqueSellingPropositions.splice(index, 1)
  },

  removePropositionById (state, propositionId) {
    const index = state.storyMode.concept.product.uniqueSellingPropositions.indexOf(
      state.storyMode.concept.product.uniqueSellingPropositions.find(proposition => proposition.id === propositionId)
    )
    state.storyMode.concept.product.uniqueSellingPropositions.splice(index, 1)
  },

  setCoverageElement (state, payload) {
    state.storyMode.research.market.coverageElement = payload.coverageElement
    state.storyMode.research.market.coverageElementSelected = true
  },

  setGrowthRateElement (state, payload) {
    state.storyMode.research.market.growthRateElement = payload.growthRateElement
    state.storyMode.research.market.growthRateElementSelected = true
  },

  addCompetitor (state, competitor) {
    state.storyMode.research.competitors.competitors.unshift(competitor)
  },

  updateCompetitor (state, competitor) {
    const index = state.storyMode.research.competitors.competitors.indexOf(
      state.storyMode.research.competitors.competitors.find(competitorToUpdate => competitorToUpdate.id === competitor.id)
    )
    Vue.set(state.storyMode.research.competitors.competitors, index, competitor)
  },

  removeCompetitor (state, competitorId) {
    const index = state.storyMode.research.competitors.competitors.indexOf(
      state.storyMode.research.competitors.competitors.find(competitorToUpdate => competitorToUpdate.id === competitorId)
    )

    state.storyMode.research.competitors.competitors.splice(index, 1)
  },

  updateCompetitorsAdvantagesTitles (state, competitor) {
    for (let i = 0; i < state.storyMode.research.competitors.competitors.length; i++) {
      state.storyMode.research.competitors.competitors[i].advantageTitle1 = competitor.advantageTitle1
      state.storyMode.research.competitors.competitors[i].advantageTitle2 = competitor.advantageTitle2
      state.storyMode.research.competitors.competitors[i].advantageTitle3 = competitor.advantageTitle3
      state.storyMode.research.competitors.competitors[i].advantageTitle4 = competitor.advantageTitle4
      state.storyMode.research.competitors.competitors[i].advantageTitle5 = competitor.advantageTitle5
    }
  },

  setStartupCostsBreakdown (state, calculation) {
    state.storyMode.setUp.startupCosts.breakdown = calculation
  },

  setStartupCostsTimeline (state, calculation) {
    state.storyMode.setUp.startupCosts.timeline = calculation
  },

  setStartupCostsTable (state, calculation) {
    state.storyMode.setUp.startupCosts.table = calculation
  },

  setStartupCosts (state, startupCosts) {
    state.storyMode.setUp.startupCosts.startupCosts = startupCosts
  },

  addStartupCost (state, startupCost) {
    state.storyMode.setUp.startupCosts.startupCosts.unshift(startupCost)
  },

  updateStartupCost (state, startupCost) {
    const index = state.storyMode.setUp.startupCosts.startupCosts.indexOf(
      state.storyMode.setUp.startupCosts.startupCosts.find(startupCostToUpdate => startupCostToUpdate.id === startupCost.id)
    )
    Vue.set(state.storyMode.setUp.startupCosts.startupCosts, index, startupCost)
  },

  removeStartupCost (state, startupCostId) {
    const index = state.storyMode.setUp.startupCosts.startupCosts.indexOf(
      state.storyMode.setUp.startupCosts.startupCosts.find(startupCostToUpdate => startupCostToUpdate.id === startupCostId)
    )

    state.storyMode.setUp.startupCosts.startupCosts.splice(index, 1)
  },

  setFinancingBreakdown (state, calculation) {
    state.storyMode.setUp.financing.breakdown = calculation
  },

  setFinancingTimeline (state, calculation) {
    state.storyMode.setUp.financing.timeline = calculation
  },

  setFinancingTable (state, calculation) {
    state.storyMode.setUp.financing.table = calculation
  },

  setFinancing (state, financing) {
    state.storyMode.setUp.financing.finances = financing
  },

  addFinance (state, finance) {
    state.storyMode.setUp.financing.finances.unshift(finance)
  },

  updateFinance (state, finance) {
    const index = state.storyMode.setUp.financing.finances.indexOf(
      state.storyMode.setUp.financing.finances.find(financeToUpdate => financeToUpdate.id === finance.id)
    )
    Vue.set(state.storyMode.setUp.financing.finances, index, finance)
  },

  removeFinance (state, financeId) {
    const index = state.storyMode.setUp.financing.finances.indexOf(
      state.storyMode.setUp.financing.finances.find(financeToUpdate => financeToUpdate.id === financeId)
    )

    state.storyMode.setUp.financing.finances.splice(index, 1)
  },

  setStaffAndPayrollBreakdown (state, calculation) {
    state.storyMode.setUp.staffAndPayrolls.breakdown = calculation
  },

  setStaffAndPayrollTimeline (state, calculation) {
    state.storyMode.setUp.staffAndPayrolls.timeline = calculation
  },

  setStaffAndPayrollTable (state, calculation) {
    state.storyMode.setUp.staffAndPayrolls.table = calculation
  },

  setStaffAndPayroll (state, staffAndPayroll) {
    state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls = staffAndPayroll
  },

  addStaffAndPayroll (state, staffAndPayroll) {
    state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.unshift(staffAndPayroll)
  },

  updateStaffAndPayroll (state, staffAndPayroll) {
    const index = state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.indexOf(
      state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.find(staffAndPayrollToUpdate => staffAndPayrollToUpdate.id === staffAndPayroll.id)
    )

    state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls[index] = staffAndPayroll
  },

  removeStaffAndPayroll (state, staffAndPayrollId) {
    const index = state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.indexOf(
      state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.find(staffAndPayrollToRemove => staffAndPayrollToRemove.id === staffAndPayrollId)
    )

    state.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.splice(index, 1)
  },

  setLaborBurden (state, laborBurden) {
    state.storyMode.setUp.staffAndPayrolls.laborBurden = laborBurden
  },

  updateLaborBurden (state, laborBurden) {
    Vue.set(state.storyMode.setUp.staffAndPayrolls, 'laborBurden', laborBurden)
  },

  setDirectCostsBreakdown (state, calculation) {
    state.storyMode.forecast.directCosts.breakdown = calculation
  },

  setDirectCostsTimeline (state, calculation) {
    state.storyMode.forecast.directCosts.timeline = calculation
  },

  setDirectCostsTable (state, calculation) {
    state.storyMode.forecast.directCosts.table = calculation
  },

  setDirectCosts (state, directCosts) {
    state.storyMode.forecast.directCosts.directCosts = directCosts
  },

  addDirectCost (state, directCost) {
    state.storyMode.forecast.directCosts.directCosts.unshift(directCost)
  },

  updateDirectCost (state, directCost) {
    const index = state.storyMode.forecast.directCosts.directCosts.indexOf(
      state.storyMode.forecast.directCosts.directCosts.find(directCostToUpdate => directCostToUpdate.id === directCost.id)
    )
    Vue.set(state.storyMode.forecast.directCosts.directCosts, index, directCost)
  },

  removeDirectCost (state, directCostId) {
    const index = state.storyMode.forecast.directCosts.directCosts.indexOf(
      state.storyMode.forecast.directCosts.directCosts.find(directCostToUpdate => directCostToUpdate.id === directCostId)
    )

    state.storyMode.forecast.directCosts.directCosts.splice(index, 1)
  },

  setRevenueStreamsBreakdown (state, calculation) {
    state.storyMode.forecast.revenueStreams.breakdown = calculation
  },

  setRevenueStreamsTimeline (state, calculation) {
    state.storyMode.forecast.revenueStreams.timeline = calculation
  },

  setRevenueStreamsTable (state, calculation) {
    state.storyMode.forecast.revenueStreams.table = calculation
  },

  setRevenueStreams (state, financing) {
    state.storyMode.forecast.revenueStreams.revenueStreams = financing
  },

  addRevenueStream (state, revenueStream) {
    state.storyMode.forecast.revenueStreams.revenueStreams.unshift(revenueStream)
  },

  updateRevenueStream (state, revenueStream) {
    const index = state.storyMode.forecast.revenueStreams.revenueStreams.indexOf(
      state.storyMode.forecast.revenueStreams.revenueStreams.find(revenueStreamToUpdate => revenueStreamToUpdate.id === revenueStream.id)
    )
    Vue.set(state.storyMode.forecast.revenueStreams.revenueStreams, index, revenueStream)
  },

  updateIsTaxableRevenueStream (state, revenueStream) {
    const index = state.storyMode.forecast.revenueStreams.revenueStreams.indexOf(
      state.storyMode.forecast.revenueStreams.revenueStreams.find(revenueStreamToUpdate => revenueStreamToUpdate.id === revenueStream.revenueStreamId)
    )

    state.storyMode.forecast.revenueStreams.revenueStreams[index].isTaxable = revenueStream.isTaxable
  },

  removeRevenueStream (state, revenueStreamId) {
    const indexOfRevenueStream = state.storyMode.forecast.revenueStreams.revenueStreams.indexOf(
      state.storyMode.forecast.revenueStreams.revenueStreams.find(revenueStreamToRemove => revenueStreamToRemove.id === revenueStreamId)
    )

    state.storyMode.forecast.directCosts.directCosts = state.storyMode.forecast.directCosts.directCosts.filter(entity => entity.revenueStreamId !== revenueStreamId)
    state.storyMode.forecast.marketingBudget.marketingElements = state.storyMode.forecast.marketingBudget.marketingElements.filter(entity => entity.revenueStreamId !== revenueStreamId)
    state.storyMode.forecast.otherOverheads.otherOverheads = state.storyMode.forecast.otherOverheads.otherOverheads.filter(entity => entity.revenueStreamId !== revenueStreamId)

    state.storyMode.forecast.revenueStreams.revenueStreams.splice(indexOfRevenueStream, 1)
  },

  updateSalesTaxRate (state, salesTaxRate) {
    state.storyMode.forecast.revenueStreams.salesTaxRate = salesTaxRate
  },

  setOtherOverheadsBreakdown (state, calculation) {
    state.storyMode.forecast.otherOverheads.breakdown = calculation
  },

  setOtherOverheadsTimeline (state, calculation) {
    state.storyMode.forecast.otherOverheads.timeline = calculation
  },

  setOtherOverheadsTable (state, calculation) {
    state.storyMode.forecast.otherOverheads.table = calculation
  },

  setOtherOverheads (state, otherOverheads) {
    state.storyMode.forecast.otherOverheads.otherOverheads = otherOverheads
  },
  addOtherOverhead (state, otherOverhead) {
    state.storyMode.forecast.otherOverheads.otherOverheads.unshift(otherOverhead)
  },

  updateOtherOverhead (state, otherOverhead) {
    const index = state.storyMode.forecast.otherOverheads.otherOverheads.indexOf(
      state.storyMode.forecast.otherOverheads.otherOverheads.find(otherOverheadToUpdate => otherOverheadToUpdate.id === otherOverhead.id)
    )
    Vue.set(state.storyMode.forecast.otherOverheads.otherOverheads, index, otherOverhead)
  },

  removeOtherOverhead (state, otherOverheadId) {
    const index = state.storyMode.forecast.otherOverheads.otherOverheads.indexOf(
      state.storyMode.forecast.otherOverheads.otherOverheads.find(otherOverheadToRemove => otherOverheadToRemove.id === otherOverheadId)
    )

    state.storyMode.forecast.otherOverheads.otherOverheads.splice(index, 1)
  },

  setMarketingBudgetBreakdown (state, calculation) {
    state.storyMode.forecast.marketingBudget.breakdown = calculation
  },

  setMarketingBudgetTimeline (state, calculation) {
    state.storyMode.forecast.marketingBudget.timeline = calculation
  },

  setMarketingBudgetTable (state, calculation) {
    state.storyMode.forecast.marketingBudget.table = calculation
  },

  setMarketingBudget (state, marketingBudget) {
    state.storyMode.forecast.marketingBudget.marketingElements = marketingBudget
  },

  addMarketingElement (state, marketingElement) {
    state.storyMode.forecast.marketingBudget.marketingElements.unshift(marketingElement)
  },

  updateMarketingElement (state, marketingElement) {
    const index = state.storyMode.forecast.marketingBudget.marketingElements.indexOf(
      state.storyMode.forecast.marketingBudget.marketingElements.find(marketingElementToUpdate => marketingElementToUpdate.id === marketingElement.id)
    )
    Vue.set(state.storyMode.forecast.marketingBudget.marketingElements, index, marketingElement)
  },

  removeMarketingElement (state, marketingElementId) {
    const index = state.storyMode.forecast.marketingBudget.marketingElements.indexOf(
      state.storyMode.forecast.marketingBudget.marketingElements.find(marketingElementToUpdate => marketingElementToUpdate.id === marketingElementId)
    )
    state.storyMode.forecast.marketingBudget.marketingElements.splice(index, 1)
  },

  setDividends (state, payload) {
    state.storyMode.forecast.profitAndCashFlow.dividends = payload
  },

  addDividend (state, dividend) {
    state.storyMode.forecast.profitAndCashFlow.dividends.unshift(dividend)
  },

  updateDividend (state, dividend) {
    const index = state.storyMode.forecast.profitAndCashFlow.dividends.indexOf(
      state.storyMode.forecast.profitAndCashFlow.dividends.find(dividendToUpdate => dividendToUpdate.id === dividend.id)
    )
    Vue.set(state.storyMode.forecast.profitAndCashFlow.dividends, index, dividend)
  },

  removeDividend (state, dividendId) {
    const index = state.storyMode.forecast.profitAndCashFlow.dividends.indexOf(
      state.storyMode.forecast.profitAndCashFlow.dividends.find(dividendToUpdate => dividendToUpdate.id === dividendId)
    )

    state.storyMode.forecast.profitAndCashFlow.dividends.splice(index, 1)
  },

  setCashCycle (state, payload) {
    state.storyMode.forecast.profitAndCashFlow.cashCycle = payload
  },

  setIncomeTax (state, payload) {
    state.storyMode.forecast.profitAndCashFlow.incomeTax = payload
  },

  setProfitAndCashFlow (state, payload) {
    state.storyMode.forecast.profitAndCashFlow.calculation = payload
  },

  setTotalCosts (state, payload) {
    state.storyMode.forecast.totalCosts.calculation = payload
  },

  setIdeaScore (state, payload) {
    state.storyMode.validation.ideaScore = payload
  },

  setVisibilityForOverviewElement (state, payload) {
    state.storyMode.overview.overviewElements = payload
  },

  updateVisibilityForOverviewElement (state, payload) {
    state.storyMode.overview.overviewElements.map(element => {
      element.isSelected = element.type === payload.type ? payload.value : element.isSelected

      return element
    })
  },

  updatePositionsForOverviewElement (state, payload) {
    const payloadLength = payload.length

    for (let i = 0; i < payloadLength; i++) {
      payload[i].orderNumber = i + 1
    }
    state.storyMode.overview.overviewElements = payload
  },

  updatePositionsForJournalElement (state, event) {
    state.journal.journalSwitchBars.splice(event.oldIndex, 1)
    state.journal.journalSwitchBars.splice(event.newIndex, 0, event.element)
    state.journal.journalSwitchBars.map((element, index) => {
      element.orderNumber = index + 1

      return element
    })
  },

  updateJournalSwitchSidebarElement (state, payload) {
    state.journal.journalSwitchBars.map(element => {
      Object.assign(element, element.id === payload.id ? payload : element)

      return element
    })
  },

  setJournalSwitchSidebarElements (state, payload) {
    state.journal.journalSwitchBars = payload
  },

  toggleOption (state, payload) {
    state.journal.journalSwitchBars.map(element => {
      if (element.id === payload.id) {
        if (element.options.includes(payload.option)) {
          element.options.splice(element.options.indexOf(payload.option), 1)
        } else {
          element.options.push(payload.option)
        }
      }

      return element
    })
  },

  addJournalSection (state, data) {
    state.journal.journalSwitchBars.push(data)

    // for (let i = 0; i < state.journal.journalSwitchBars.length; i++) {
    //   state.journal.journalSwitchBars[i].orderNumber = i + 1
    // }
  },

  removeJournalSection (state, journalId) {
    const index = state.journal.journalSwitchBars.indexOf(
      state.journal.journalSwitchBars.find(journalToUpdate => journalToUpdate.id === journalId)
    )

    state.journal.journalSwitchBars.splice(index, 1)
  },

  updateIdeaPlanTitle (state, ideaPlan) {
    const index = state.ideaPlan.ideaPlans.indexOf(
      state.ideaPlan.ideaPlans.find(ideaPlanToUpdate => ideaPlanToUpdate.id === ideaPlan.id)
    )

    state.ideaPlan.ideaPlan.title = ideaPlan.title
    state.ideaPlan.ideaPlans[index] = ideaPlan
  },

  setIdeaPlanNote (state, note) {
    state.ideaPlanNote.ideaPlanNotes = note
  },

  setGuide (state, payload) {
    state.ideaGuide = payload.guide
  },

  setIsSectionEverComplete (state, { section, value }) {
    state.storyMode.common.completedSections[section] = value
  },

  setTipOpen (state, payload) {
    state.tipOpen = payload
  },

  setOwnerAiRequests (state, payload) {
    state.ownerAiRequests = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    ideaPlan,
    ownership,
    ideaWhiteBoard
  }
}
