const getDefaultState = () => {
  return {
    menuCollapsed: false,
    home: {
      gettingStartedExpanded: true
    },

    handWritings: {
      financialPlanOverview: true,
      businessGuideIdea: true
    },

    businessPlanAdditionalInfoExpand: {
      Idea: true,
      Product: true,
      Promotion: true,
      Distribution: true,
      Partner: true,
      Market: true,
      Customers: true,
      Competitors: true,
      SwotAnalysis: true,
      MarketPotential: true,
      ManagementTeam: true,
      Ownership: true
    }
  }
}

const state = getDefaultState()

const getters = {
  isMenuCollapsed (state) {
    return !!state.menuCollapsed
  },

  getGettingStartedExpanded (state) {
    return state.home.gettingStartedExpanded
  },

  getHandWritings (state) {
    return state.handWritings
  },

  getBusinessPlanAdditionalInfoExpand: (state) => (section) => {
    return state.businessPlanAdditionalInfoExpand[section]
  }
}

const mutations = {
  setMenuCollapsedToggle (state) {
    state.menuCollapsed = !state.menuCollapsed
  },

  setGettingStartedExpandedState (state, data) {
    state.home.gettingStartedExpanded = data
  },

  setHandWriting (state, { key, value }) {
    state.handWritings[key] = value
  },

  setBusinessPlanAdditionalInfoExpanded (state, { key, value }) {
    state.businessPlanAdditionalInfoExpand[key] = value
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
